<template>
  <div class="about">
    <headerNav pathname="关于我们" />
    <!-- <img class="banner" src="" alt /> -->
    <text-common
      title="关于我们"
      :img="img"
      :writtenWords_img="true"
    />
    <!-- 公司介绍 -->
    <div class="about_us_bg">
      <div class="company_name">上海驮龙物流有限公司</div>
      <div class="company_pinyin">
        <span style="opacity: 0.25">Shanghai Tuolong Logistics.,Ltd</span>
        <div class="Underline"></div>
      </div>

      <div
        class="about_us_text_bg"
        style="line-height: 30px"
      >
        <div style="opacity: 0.85; font-weight: 100; width: 1103px;text-indent:2em;text-align: left;">
          上海驮龙物流有限公司成立于2020年1月，注册资金3000万人民币，总部设立于上海，公司致力于成为行业领先的公铁联运平台型物流企业。
          公司通过强强联合、资源整合、资本运作，整合了长三角、西南、西北市场的公铁运力资源，迅速形成了覆盖上海、浙江、江苏、新疆、甘肃、西藏、云南、贵州、四川等区域的公铁联运物流网，业务涉及各地主要产业聚集区，公司不断开拓新业务并将持续服务于广大顾客。
          公司拥有专业化、年轻化的员工队伍，丰富的行业经验、优势的IT科技实力，提供公铁联运物流平台为物流行业降本增效，为客户提高产品附加值创造价值；公司不断提升服务品质、创造服务价值，并且通过技术创新、业务创新、资源整合等不断增强企业核心竞争力，实现优势核心业务并打造具有行业影响力的公铁联运平台物流企业。

        </div>
      </div>

      <div class="aboutUsList_bg">
        <div
          class="aboutUsList"
          :style="{
            width: item.width,
            paddingRight: item.paddingRight,
            paddingLeft: item.paddingRight,
            borderLeft: item.borderLeft,
          }"
          v-for="(item, index) in aboutUsList"
          :key="index"
        >
          <div
            class="aboutUs_title_bg"
            @mouseleave="handelLeave(index)"
            @mouseenter="handelenter(index)"
          >
            <img
              width="36px"
              height="36px"
              :src="aboutUsHover == item.index ? item.img_act : item.img"
              alt=""
            />
            <div :class="aboutUsHover == item.index ? 'aboutUs_title' : ''">
              {{ item.title }}
            </div>
          </div>
        </div>
      </div>

      <img
        width="800px"
        style="margin-bottom: 109px"
        src="../assets/img/contenimg.png"
        alt=""
      />
    </div>
    <bottom-navigation />
  </div>
</template>

<script>
import Swiper from "swiper"; //引入swiper依赖
import "swiper/dist/css/swiper.min.css";
export default {
  data() {
    return {
      img: require("@/assets/about_us/about_us_banner.png"),
      bannerList: [
        {
          banner: require("@/assets/about_us/about_us_banner.png"),
        }
      ],
      scroll: '',
      aboutUsList: [
        {
          img: require("@/assets/img/sincerity.png"),
          img_act: require("@/assets/img/sincerity_act.png"),
          index: 0,
          title: "诚信",
          width: "222px",
          paddingRight: "36px",
          paddingLeft: "36px",
        },
        {
          img: require("@/assets/img/quick .png"),
          img_act: require("@/assets/img/quick_act.png"),
          index: 1,
          title: "快捷",
          width: "222px",
          paddingRight: "36px",
          paddingLeft: "36px",
          borderLeft: '1px solid rgba(0, 0, 0, 0.1',
        },
        {
          img: require("@/assets/img/security.png"),
          img_act: require("@/assets/img/security_act.png"),
          index: 2,
          title: "安全",
          width: "222px",
          paddingRight: "36px",
          paddingLeft: "36px",
          borderLeft: '1px solid rgba(0, 0, 0, 0.1',
        },
        {
          img: require("@/assets/img/Efficient.png"),
          img_act: require("@/assets/img/Efficient_act.png"),
          index: 3,
          title: "高效",
          width: "222px",
          paddingRight: "36px",
          paddingLeft: "36px",
          borderLeft: '1px solid rgba(0, 0, 0, 0.1',
        },
      ],
      aboutUsHover: null
    }
  },
  mounted() {
    this.getBanner(); //轮播
    window.addEventListener('scroll', this.menu)
  },
  methods: {

    menu() {
      this.scroll = document.documentElement.scrollTop || document.body.scrollTop;
      //   console.log(this.scroll);
    },
    // 鼠标移出
    handelLeave(a) {
      this.aboutUsHover = null
    },
    // 鼠标移入
    handelenter(index) {
      this.aboutUsHover = index
    },

    //封装轮播函数
    getBanner() {
      //调用延迟加载 $nextTick
      this.$nextTick(() => {
        var mySwiper = new Swiper(".swiper-container", {
          loop: true, // 循环模式选项
          autoplay: true, //自动切换
          //   virtualTranslate: true,
          //   autoplay: {
          //     delay: 1000,
          //   },
          // 如果需要分页器
          pagination: {
            el: ".swiper-pagination"
          }
        });
      });

    },
  }

}
</script>

<style lang="less" scoped>
@font-face {
  font-family: "DIN-Condensed";
  src: url(../assets/font/DIN-Condensed.ttf);
}
.about {
  .banner {
    width: 100%;
  }

  .aboutUsList_bg {
    display: flex;
    width: 1115px;
    margin: 28px auto 76px auto;
    justify-content: center;
    text-align: center;
    .aboutUsList:nth-child(1) {
      // background: rosybrown;
      border-right: rgba(0, 0, 0, 0.1);
    }

    .aboutUs_title_bg {
      width: 36px;
      margin: 0 auto;
      .aboutUs_title {
        color: #734f21;
      }
    }
  }

  .about_us_bg {
    text-align: center;
    width: 100%;
    margin-top: 95px;
    // padding: 96px 400px 109px 400px;
    box-sizing: border-box;
    font-family: PingFangSC-Medium;
    color: #000;
    .about_us_text_bg {
      width: 1110px;
      margin: 0 auto;
      font-weight: 100;
    }
    .company_name {
      color: #734f21;
      font-size: 24px;
      font-weight: 500;
    }
    .company_pinyin {
      font-size: 14px;
      margin-bottom: 26px;
    }
    .Underline {
      width: 28px;
      height: 5px;
      background: #734f21;
      margin: 5px auto;
    }
  }
}
</style>