var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"about"},[_c('headerNav',{attrs:{"pathname":"关于我们"}}),_c('text-common',{attrs:{"title":"关于我们","img":_vm.img,"writtenWords_img":true}}),_c('div',{staticClass:"about_us_bg"},[_c('div',{staticClass:"company_name"},[_vm._v("上海驮龙物流有限公司")]),_vm._m(0),_vm._m(1),_c('div',{staticClass:"aboutUsList_bg"},_vm._l((_vm.aboutUsList),function(item,index){return _c('div',{key:index,staticClass:"aboutUsList",style:({
          width: item.width,
          paddingRight: item.paddingRight,
          paddingLeft: item.paddingRight,
          borderLeft: item.borderLeft,
        })},[_c('div',{staticClass:"aboutUs_title_bg",on:{"mouseleave":function($event){return _vm.handelLeave(index)},"mouseenter":function($event){return _vm.handelenter(index)}}},[_c('img',{attrs:{"width":"36px","height":"36px","src":_vm.aboutUsHover == item.index ? item.img_act : item.img,"alt":""}}),_c('div',{class:_vm.aboutUsHover == item.index ? 'aboutUs_title' : ''},[_vm._v(" "+_vm._s(item.title)+" ")])])])}),0),_c('img',{staticStyle:{"margin-bottom":"109px"},attrs:{"width":"800px","src":require("../assets/img/contenimg.png"),"alt":""}})]),_c('bottom-navigation')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"company_pinyin"},[_c('span',{staticStyle:{"opacity":"0.25"}},[_vm._v("Shanghai Tuolong Logistics.,Ltd")]),_c('div',{staticClass:"Underline"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"about_us_text_bg",staticStyle:{"line-height":"30px"}},[_c('div',{staticStyle:{"opacity":"0.85","font-weight":"100","width":"1103px","text-indent":"2em","text-align":"left"}},[_vm._v(" 上海驮龙物流有限公司成立于2020年1月，注册资金3000万人民币，总部设立于上海，公司致力于成为行业领先的公铁联运平台型物流企业。 公司通过强强联合、资源整合、资本运作，整合了长三角、西南、西北市场的公铁运力资源，迅速形成了覆盖上海、浙江、江苏、新疆、甘肃、西藏、云南、贵州、四川等区域的公铁联运物流网，业务涉及各地主要产业聚集区，公司不断开拓新业务并将持续服务于广大顾客。 公司拥有专业化、年轻化的员工队伍，丰富的行业经验、优势的IT科技实力，提供公铁联运物流平台为物流行业降本增效，为客户提高产品附加值创造价值；公司不断提升服务品质、创造服务价值，并且通过技术创新、业务创新、资源整合等不断增强企业核心竞争力，实现优势核心业务并打造具有行业影响力的公铁联运平台物流企业。 ")])])
}]

export { render, staticRenderFns }